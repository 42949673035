import { Link } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { type AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useCallback, useMemo, useRef } from 'react';
import { Waypoint } from 'react-waypoint';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useListLoader } from '../../hooks/useListLoader';
import { apiService, type PairingsResponse } from '../../services/api-service';
import { type DmgRenderResponse } from '../../services/api-service/dmg.api';
import {
  type Pairing,
  PairingRoundUtils,
  type SessionTrack,
  SessionUtils,
} from '../../types';
import { ErrorMessage } from '../Game/GameCenter';
import { ArrowLeftIcon } from '../icons/Arrows';
import { ImageIcon } from '../icons/ImageIcon';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import { Loading } from '../Loading';
import {
  MessageTemplateTranslator,
  useTranslateMessageTemplates,
} from '../MessageTemplate';
import { useOrgContext } from '../Organization/Details';
import { PairingDetails } from './PairingDetails';
import { usePairingMessageTemplateVars } from './PairingMessageTemplates';
import { PairingRoundExportButton } from './PairingRoundExportButton';
import { usePairingRoundDetails } from './Round';

const SessionLink = (props: { session: SessionTrack }): JSX.Element => {
  const { session } = props;
  return (
    <Link
      className='text-sms border-b border-primary text-primary'
      to={SessionUtils.ComposeInspectSessionUrl(session)}
    >{`${new Date(session.startedAt).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })} - ${session.gamePackName}`}</Link>
  );
};

const PairingSession = (props: { pairing: Pairing }): JSX.Element => {
  const { pairing } = props;

  return (
    <div className='w-full h-full flex flex-col justify-center items-center gap-4'>
      {!pairing.mainSession && !pairing.additionalSessions?.length && 'N/A'}
      {pairing.mainSession && <SessionLink session={pairing.mainSession} />}
      {pairing.additionalSessions && pairing.additionalSessions.length > 0 && (
        <div className='flex flex-col items-center gap-1'>
          <p>Additional Sessions:</p>
          <p className='text-center'>
            {pairing.additionalSessions.map((session, index) => (
              <span key={session.id}>
                {index !== 0 && ', '}
                <SessionLink session={session} />
              </span>
            ))}
          </p>
        </div>
      )}
    </div>
  );
};

function GenerateMediaButton(props: {
  generator: () => Promise<AxiosResponse<DmgRenderResponse>>;
  children?: React.ReactNode;
}): JSX.Element {
  const { generator } = props;
  const {
    state: { transformed: callState },
    call,
  } = useAsyncCall(
    useCallback(async () => {
      const resp = await generator();
      return resp.data.url;
    }, [generator])
  );

  const handleClick = async () => {
    const maybeUrl = await call();
    if (maybeUrl) {
      window.open(maybeUrl, '_blank');
    }
  };

  return (
    <button
      type='button'
      className='disabled:opacity-50 flex items-center gap-1'
      onClick={handleClick}
      disabled={callState.isRunning}
    >
      {props.children ? (
        props.children
      ) : (
        <div className='btn text-primary text-2xs underline'>Open</div>
      )}
      {callState.isRunning && <Loading text='' imgClassName='w-4 h-4' />}
    </button>
  );
}

export const PairingList = (): JSX.Element => {
  const navigate = useNavigate();

  const { org } = useOrgContext();
  const { round } = usePairingRoundDetails();

  const paginator = useMemo(
    () => apiService.pairing.getPairingsByRoundId(round.id),
    [round.id]
  );
  const { items, dao, state, error, handleRetry, handleLoadMore } =
    useListLoader<PairingsResponse, Pairing>(
      paginator,
      (a, b) => a.id === b.id
    );
  const showEmptyMsg = state.isDone && !error && items.length === 0;
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  const messageTemplateVars = usePairingMessageTemplateVars({
    org,
    round,
    pairings: items,
  });
  const translateMessageTemplate = useTranslateMessageTemplates();

  const pairingListDivRef = useRef<HTMLDivElement>(null);
  const handleCreatePairing = async () => {
    const resp = await apiService.pairing.createPairing(round.id);
    if (!resp) return;
    dao.addItem(resp.data.pairing);
    pairingListDivRef.current?.scrollTo({ top: 0 });
  };

  const handlePairingUpdated = (value: Pairing) => {
    dao.updateItem(value);
  };

  const handlePairingDeleted = (value: Pairing) => {
    dao.deleteItem(value);
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <button
        type='button'
        className='btn text-sms flex items-center gap-3'
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowLeftIcon /> Back
      </button>

      <div className='mt-3 flex justify-between items-center'>
        <div>
          <h1 className='text-xl font-medium'>
            {round.mainGamePack ? (
              <div>
                {round.mainGamePack.name}
                <Link
                  to={`/admin/programs/global-pairing-rounds/${round.globalRoundId}`}
                  className='text-base text-primary ml-2'
                  target='_blank'
                >
                  Edit
                </Link>
              </div>
            ) : (
              <span className=' text-red-002'>ERROR</span>
            )}
          </h1>
          <h2 className='font-medium text-icon-gray'>
            {format(new Date(round.startedAt), 'MM/dd/yyyy')} -{' '}
            {format(new Date(round.endedAt), 'MM/dd/yyyy')}
          </h2>
          <Link
            to={`/admin/programs/message-campaigns/${round.globalRound?.messageCampaignId}`}
            target='_blank'
          >
            <div className='font-medium text-icon-gray'> Message Campaign</div>
          </Link>
        </div>
        <div className='flex items-center gap-8'>
          <a
            href={PairingRoundUtils.GetLeaderboardURL(round)}
            className='btn text-xs font-light text-icon-gray flex items-center gap-2 border-b border-icon-gray rounded-none'
          >
            <ScoreboardIcon />
            Leaderboard
          </a>

          <GenerateMediaButton
            generator={() =>
              apiService.dmg.renderTemplate({
                templateId: 'Scoreboard',
                roundId: round.id,
              })
            }
          >
            <div className='btn text-xs font-light text-icon-gray flex items-center gap-2 border-b border-icon-gray rounded-none'>
              <ImageIcon />
              Leaderboard GIF
            </div>
          </GenerateMediaButton>

          <PairingRoundExportButton
            org={org}
            round={round}
            pairings={items}
            translateMessageTemplates={(_, index) => {
              return translateMessageTemplate(messageTemplateVars[index]) || [];
            }}
          />
          <button
            type='button'
            className='btn-primary w-50 h-12.5'
            onClick={handleCreatePairing}
          >
            Create Pairing
          </button>
        </div>
      </div>

      <div className='flex-shrink overflow-y-auto scrollbar mt-7 w-full flex flex-col'>
        <div className='w-full flex items-center text-center font-bold'>
          <p className='w-3/12'>Pairings</p>
          <p className='w-4/12'>Pairing Session</p>
          <p className='w-2/12'>Score</p>
          <p className='w-3/12'>Quick Links</p>
        </div>

        <div
          ref={pairingListDivRef}
          className='flex-shrink overflow-y-auto scrollbar mt-4 w-full flex flex-col gap-4 text-sms'
        >
          {items.map((item, index) => (
            <div key={item.id} className='w-full bg-dark-gray rounded-xl flex'>
              <div className='w-3/12 min-h-36 border-r border-secondary p-2'>
                <PairingDetails
                  org={org}
                  allPairings={items}
                  pairing={item}
                  onDeleted={handlePairingDeleted}
                  onUpdated={handlePairingUpdated}
                />
              </div>
              <div className='w-4/12 border-r border-secondary p-2'>
                <PairingSession pairing={item} />
              </div>
              <div className='w-2/12 border-r border-secondary flex justify-center items-center max-h-72 overflow-y-auto scrollbar'>
                {item.mainSession
                  ? `${item.mainSession.maxScore} Points`
                  : 'N/A'}
              </div>
              <div className='w-3/12 px-4.5 py-3 max-h-72 overflow-y-auto scrollbar'>
                <MessageTemplateTranslator
                  includePredefined={true}
                  vars={messageTemplateVars[index]}
                  roundId={item.roundId}
                  pairingId={item.id}
                />
              </div>
            </div>
          ))}

          <div>
            {showEmptyMsg && (
              <div className='w-full mt-32 flex items-center justify-center font-bold text-secondary'>
                No Pairings
              </div>
            )}
            {state.isRunning && <Loading />}
            {error && (
              <div className='w-full flex items-center justify-center'>
                <ErrorMessage
                  text='Something went wrong'
                  handleRetry={handleRetry}
                />
              </div>
            )}
            {canLoadMore && (
              <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
                <div>&nbsp;</div>
              </Waypoint>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
