import { PairingList } from '../components/Pairing';

export function OrganizationPairingRoundDetailsPageRoute() {
  return <PairingList />;
}

export const Component = OrganizationPairingRoundDetailsPageRoute;

export function clientLoader() {
  return null;
}
