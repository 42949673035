import { ExportToCsv } from 'export-to-csv';

import {
  type Organization,
  type Pairing,
  PairingPlayerUtils,
  type PairingRound,
  PairingUtils,
} from '../../types';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { DownloadIcon } from '../icons/DownloadIcon';
import { type TranslatedMessage } from '../MessageTemplate/types';
import { type PairingInfoExportedRow } from './types';

export const PairingRoundExportButton = (props: {
  org: Organization;
  round: PairingRound;
  pairings: Pairing[];
  translateMessageTemplates(
    pairing: Pairing,
    index: number
  ): TranslatedMessage[];
}): JSX.Element => {
  const { org, pairings, round, translateMessageTemplates } = props;

  const exportMessageTemplates = () => {
    const filename = `Random Pairing Message Template - Round ${new Date(
      round.createdAt
    ).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })}`;
    const rows = pairings.map((pairing, index) => {
      const row = {
        'Pairing ID': pairing.id,
        Players: pairing.players
          ?.map((p) => PairingPlayerUtils.GetDisplayName(p))
          .join(','),
        'Work Emails': pairing.players
          ?.map((p) => p.organizer?.email)
          .join(','),
      };

      const messages = translateMessageTemplates(pairing, index);
      for (const message of messages) {
        uncheckedIndexAccess_UNSAFE(row)[message.title] = message.body;
      }

      return row;
    });

    const exporter = new ExportToCsv({
      useKeysAsHeaders: true,
      filename: filename,
    });
    exporter.generateCsv(rows);
  };

  const exportInfo = () => {
    const filename = `Random Pairing Info - Round ${new Date(
      round.createdAt
    ).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })}`;
    const rows: PairingInfoExportedRow[] = [];
    for (const pairing of pairings) {
      if (!pairing.players) continue;

      for (const player of pairing.players) {
        const row: PairingInfoExportedRow = {
          'Pairing ID': pairing.id,
          'Round Generate Date': new Date(round.createdAt).toLocaleDateString(
            'en-US',
            {
              month: '2-digit',
              day: '2-digit',
              year: '2-digit',
            }
          ),
          Organization: org.name,
          Players: PairingPlayerUtils.GetDisplayName(player),
          'Organizer?': PairingPlayerUtils.IsOrganizer(player, pairing)
            ? 'Yes'
            : 'No',
          Attended: player.isGameCompleted ? 'Yes' : 'No',
          'Team Name': pairing.teamName ?? 'N/A',
          'Date Played': pairing.mainSession
            ? new Date(pairing.mainSession.startedAt).toLocaleDateString(
                'en-US',
                {
                  month: '2-digit',
                  day: '2-digit',
                  year: '2-digit',
                }
              )
            : 'N/A',
          'Game Pack Played': pairing.mainSession?.gamePackName ?? 'N/A',
          Score: String(
            pairing.score || pairing.mainSession?.maxScore || 'N/A'
          ),
          'Game URL': PairingUtils.GetGameURL(pairing),
        };
        rows.push(row);
      }
    }

    const exporter = new ExportToCsv({
      useKeysAsHeaders: true,
      filename: filename,
    });
    exporter.generateCsv(rows);
  };

  const handleClick = () => {
    exportInfo();
    exportMessageTemplates();
  };

  return (
    <button
      type='button'
      className='btn text-xs font-light text-icon-gray flex items-center gap-2 border-b border-icon-gray rounded-none'
      onClick={handleClick}
    >
      <DownloadIcon className='w-3.5 h-3.5 fill-current' />
      Export CSV
    </button>
  );
};
